.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;

  
}

@media (max-width: 767px) { /* Apply styles only for smartphones */
  .root h2 {
    font-size: 25px; /* Adjust font size for <h2> elements on this page */
  }
  .root h1 {
    font-size: 26px; /* Adjust font size for <h1> elements on this page */
    word-break: keep-all; /* Prevent word breaks */
    white-space: normal;  /* Allow wrapping at spaces */
    hyphens: none;
    text-align: center;
    justify-self: center;
  }
}
